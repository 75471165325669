import EnterpriseForm from '@/components/EnterpriseForm';
import { seoConfiguration } from '@/utils/seo-config';

const GetDemo = () => {
  return (
    <EnterpriseForm
      content={content}
      formGUID="8c88f361-014c-4bd9-936d-8bb646b13f51"
      submitSuccessMessage={content.submitSuccessMessage}
      eventCode="get_demo"
      onFormReady={($form: unknown) => {
        // @ts-ignore
        const inputs = [...$form];

        const controlPlanes = inputs.find((i) => i.name === 'controlplanes');
        const apps = inputs.find((i) => i.name === 'applications');

        const _bundle = sessionStorage.getItem(keys.pricingBundle());

        if (_bundle) {
          const bundle: Package = JSON.parse(_bundle);

          controlPlanes.value =
            bundle.controlPlanes > MAXIMUM_CONTROL_PLANE_ESTIMATION
              ? `${MAXIMUM_CONTROL_PLANE_ESTIMATION}+`
              : bundle.controlPlanes;

          apps.value =
            bundle.applications > MAXIMUM_APPLICATION_ESTIMATION
              ? `${MAXIMUM_APPLICATION_ESTIMATION}+`
              : bundle.applications;
        }
      }}
    />
  );
};

const content = {
  seo: seoConfiguration['get-demo'],
  header: 'Get a Technical Demo',
  subheader: 'Ready to learn more about the Akuity Platform?',
  formHelper: `Fill out the form - we'll reach out to you as soon as possible. Please use your work email!`,
  submitSuccessMessage: 'Thanks you for connecting with us.'
};

export default GetDemo;
